import React from "react";
import { StyledGrid, StyledGridCol } from "../utils/StyledComponents";
import { Heading } from "@abyss/web/ui/Heading/Heading";
import { Alert } from "@abyss/web/ui/Alert";
import { Label } from "@abyss/web/ui/Label/Label";
import { Text } from "@abyss/web/ui/Text";
import { useLocation} from "react-router-dom";
import PrintButton from "../utils/PrintButton";

const IndianaHospiceConfirmation = () => {
    const location = useLocation();
    const data = location.state?.data;
  return (
    <StyledGrid container>  
    <StyledGrid section paddingYZero>
        <StyledGridCol span={11} rowGapLg>
          <Heading offset={2}>Confirmation and Summary</Heading>
          <Alert
            title="Indiana hospice notification form submission complete"
            variant="success"
          >
            A UnitedHealthcare Representative will be in contact with you,
            please allow up to 10 calendar days for response.<br></br>
          </Alert>
        </StyledGridCol>
        <PrintButton confirmation />
    </StyledGrid>

    <StyledGrid section>
        <StyledGridCol span={12} paddingYZero>
          <Heading offset={4}> Provider contact information</Heading>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Name</Label>
          <Text>{data?.phoneNumber ? data.phoneNumber : "-"}</Text>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Phone</Label>
          <Text>{data?.contactPhone ? data.contactPhone : "-"}</Text>
        </StyledGridCol>
    </StyledGrid>

    <StyledGrid section span={12} paddingYZero>
    <StyledGridCol span={12} paddingYZero>  
    <Heading offset={4}> Member information</Heading>
    </StyledGridCol>
    <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Full Name</Label>
          <Text>{data?.memberFullName ? data.memberFullName : "-"}</Text>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Medicaid ID</Label>
          <Text>{data?.medicaidId ? data.medicaidId : "-"}</Text>
        </StyledGridCol>
     
        <StyledGridCol
          span={12}
          paddingYZero
          wordWrap
        >
          <Label size="md">Diagnosis code(s)</Label>
          <Text>{data?.diagnosisCode ? data.diagnosisCode : "-"}</Text>
        </StyledGridCol>
        
    </StyledGrid>

    <StyledGrid section span={12} paddingYZero>  
    <StyledGridCol span={12}>
          <Heading offset={4}>Additional comments</Heading>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Comments</Label>
          <Text>{data?.additionalComments ? data.additionalComments : "-"}</Text>
        </StyledGridCol>
    </StyledGrid>

    <StyledGrid section span={12} paddingYZero>
    <StyledGridCol span={12}>
          <Heading offset={4}>Files uploaded</Heading>
        </StyledGridCol>
    </StyledGrid>
    </StyledGrid>
  );
};

export default IndianaHospiceConfirmation;