import React from "react";
import { Heading } from "@abyss/web/ui/Heading/Heading";
import { FileUpload } from '@abyss/web/ui/FileUpload';
import { StyledGrid,StyledGridCol } from "./StyledComponents";


const DocUpload = () => {
  return (
   <StyledGrid section>
    <StyledGridCol paddingYZero span={12}>
            <Heading offset={4}>File upload</Heading>
          </StyledGridCol>
   <StyledGridCol paddingYZero span= {12}>
   <FileUpload
        uploadMessage="Drag your PDF files here"
        model="files"
        maxFileSize={1}
        maxFiles={3}
        fileTypes={{'application/pdf': ['.pdf']}}
      />
   </StyledGridCol>
   </StyledGrid>
  );
}

export default DocUpload;