import { Grid } from "@abyss/web/ui/Grid";
import { styled } from "@abyss/web/tools/styled";
export const StyledGrid = styled(Grid, {
  static: {
    variants: {
      container: {
        true: {
          marginTop: "24px!important",
          padding: "0 24px 24px 24px !important",
          display: "grid",
          rowGap: "24px",
          width: "100%",
          justifyContent: "normal !important"
        },
      },
      section: {
        true: {
          rowGap: 16,
          padding: "8px 12px !important",
        },
      },
      rowGapSm: {
        true: {
          rowGap: 12,
        },
      },
      rowGapMd: {
        true: {
          rowGap: 16,
        },
      },
      rowGapLg: {
        true: {
          rowGap: 24,
        },
      },
      fullWidth: {
        true: {
          width: "100%",
        },
      },
      paddingYMd: {
        true: {
          paddingTop: "24px !important",
          paddingBottom: "24px !important",
        },
      },
      paddingXMd: {
        true: {
          paddingRight: "24px !important",
          paddingLeft: "24px !important",
        },
      },
      paddingYZero: {
        true: {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
        },
      },
      paddingXZero: {
        true: {
          paddingLeft: "0px !important",
          paddingRight: "0px !important",
        },
      },
      marginLeftMd: {
        true: {
          marginLeft: "24px",
        },
      },
    },
  },
});

export const StyledGridCol = styled(Grid.Col, {
  static: {
    display: "grid",
    variants: {
      rowGapSm: {
        true: {
          rowGap: 12,
        },
      },
      rowGapMd: {
        true: {
          rowGap: 16,
        },
      },
      rowGapLg: {
        true: {
          rowGap: 24,
        },
      },
      colGapSm: {
        true: {
          colGap: 4,
        },
      },
      wordWrap: {
        true: {
          wordBreak: "break-all",
        },
      },
      paddingYXs: {
        true: {
          paddingTop: "2px!important",
          paddingBottom: "2px!important",
        },
      },
      paddingXsm: {
        true: {
          paddingLeft: "4px!important",
          paddingRight: "4px!important",
        },
      },
      paddingXXs: {
        true: {
          paddingLeft: "2px!important",
          paddingRight: "2px!important",
        },
      },
      paddingYZero: {
        true: {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
        },
      },
      paddingXZero: {
        true: {
          paddingLeft: "0px !important",
          paddingRight: "0px !important",
        },
      },
      pointer: {
        true: {
          cursor: "pointer",
        },
      },
    },
  },

  dynamic: ({ size }) => {
    return {
      rowGap: size * 4,
    };
  },
});

export const RecaptchaComponent = styled("div", {
  width: "30%",
  padding: "0px 12px",
});
